import HttpStatus from 'components/Common/HttpStatus'
import RedirectWithStatus from 'components/Common/RedirectWithStatus'
import Error404PageWithOffers from 'components/Pages/Error404PageWithOffers/Error404PageWithOffers'
import config from 'constants/config'
import React, { ComponentProps } from 'react'
import { connect } from 'react-redux'
import { Switch, useRouteMatch, Route as BaseRoute, RouteComponentProps } from 'react-router'
import Route from './TrackedRoute'
import loadable from 'components/utils/lazyImport'
import {
  OFFER_TYPE_BUNDLE_AND_SAVE_SLUG,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_HOTEL_SLUG,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_LAST_MINUTE_SLUG,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_SLUG,
  PRODUCT_TYPE_ULTRALUX_SLUG,
  PRODUCT_TYPE_HOME_AND_VILLAS_SLUG,
} from 'constants/offer'
import { TYPE_OF_PAGE_DESTINATION, TYPE_OF_PAGE_HOLIDAY } from 'constants/landingPage'
import { TYPE_PAGE_LIMIT } from 'constants/search'
import { isCoverGeniusEnabled } from 'lib/insurance/insuranceHelpers'
import AuthRoute from './AuthRoute'
import { isFlightsCredit, isStandaloneFlightsEnabled } from 'selectors/flightsSelectors'
import { FRONT_PAGE_MAP_VIEW_OFFERS_TYPES } from 'constants/offerList'
import TripPlannerRoutes from 'tripPlanner/TripPlannerRoutes'
import { LUXURY_ESCAPES } from 'constants/brands'
import { isStandaloneCruiseEnabled } from 'selectors/cruiseOfferSelectors'
import BusinessTravellerPageRoutes from 'businessTraveller/routes/BusinessTravellerPageRoutes'
import { buildPath } from 'lib/url/routeUtils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import AgentHubGuestPageRoutes from 'agentHub/routes/AgentHubGuestPageRoutes'
import { useAppSelector } from 'hooks/reduxHooks'
import { LoadableComponent } from '@loadable/component'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import { getStandaloneAirportTransfersEnabled } from 'selectors/airportTransferSelectors'
import DestinationLandingPage from 'components/Pages/DestinationLandingPage/DestinationLandingPage'
import CruiseLocationLandingPage from 'components/Cruises/CruiseSEOpage/CruiseLocationLandingPage'
import CruiseShipsLandingPage from 'components/Cruises/CruiseSEOpage/CruiseShipsLandingPage'

const HotelSearchFullScreenPage = loadable(() => import(/* webpackChunkName: "HotelSearchFullScreenPage" */ 'components/Pages/HotelSearchPage/HotelSearchFullScreenPage'))
const HotelSearchPage = loadable(() => import(/* webpackChunkName: "HotelSearchPage" */ 'components/Pages/HotelSearchPage/HotelSearchPage'))
const HotelSearchMapPage = loadable(() => import(/* webpackChunkName: "HotelSearchMapPage" */ 'components/Pages/HotelSearchPage/HotelSearchMapPage'))
const SupportPage = loadable(() => import(/* webpackChunkName: "SupportPage" */ 'components/Pages/SupportPage/SupportPage'))
const VideoChatPage = loadable(() => import(/* webpackChunkName: "VideoChatPage" */ 'components/Pages/VideoChatPage'))
const AboutUsPage = loadable(() => import(/* webpackChunkName: "AboutUsPage" */ 'components/Pages/AboutUsPage'))
const CareersPage = loadable(() => import(/* webpackChunkName: "CareersPage" */ 'components/Pages/CareersPage'))
const StaticPage = loadable(() => import(/* webpackChunkName: "StaticPage" */ 'components/Pages/StaticPage'))
const BestPricePage = loadable(() => import(/* webpackChunkName: "BestPricePage" */ 'components/Pages/BestPricePage'))
const FlightsLandingPage = loadable(() => import(/* webpackChunkName: "FlightsLandingPage" */ 'components/Pages/PromotionalPages/FlightsLandingPage'))
const FlightsSearchLandingPage = loadable(() => import(/* webpackChunkName: "FlightsSearchLandingPage" */ 'components/Pages/PromotionalPages/FlightsLandingPage/FlightsSearchLandingPage'))
const FlightsSearchPage = loadable(() => import(/* webpackChunkName: "FlightsSearchPage" */ 'components/Flights/FlightsSearchPage'))
const FlightsSearchResultsPage = loadable(() => import(/* webpackChunkName: "FlightsSearchResultsPage" */ 'components/Flights/FlightsSearchResultsPage'))
const FlightsSearchMultiCityResultsPage = loadable(() => import(/* webpackChunkName: "FlightsSearchMultiCityResultsPage" */ 'components/Flights/FlightsSearchMultiCityResultsPage'))
const LegalPages = loadable(() => import(/* webpackChunkName: "LegalPages" */ 'components/Pages/LegalPages'))
const ReferPage = loadable(() => import(/* webpackChunkName: "ReferPage" */ 'marketing/pages/Refer'))
const NewsPage = loadable(() => import(/* webpackChunkName: "NewsPage" */ 'components/Pages/NewsPage'))
const FrequentFlyerPage = loadable(() => import(/* webpackChunkName: "FrequentFlyerPage" */ 'components/Pages/FrequentFlyerPage'))
const MyEscapesPage = loadable(() => import(/* webpackChunkName: "MyEscapesPage" */ 'components/Pages/MyEscapesPage'))
const UserReviews = loadable(() => import(/* webpackChunkName: "UserReviews" */ 'components/Pages/UserReviews'))
const OrderPage = loadable(() => import(/* webpackChunkName: "OrderPage" */ 'components/Pages/MyEscapesPage/OrderPage'))
const AirportTransferPage = loadable(() => import(/* webpackChunkName: "OrderPage" */ 'components/Pages/MyEscapesPage/AirportTransfers'))
const PreferenceCentrePage = loadable(() => import(/* webpackChunkName: "PreferenceCentrePage" */ 'components/Pages/PreferenceCentrePage'))
const MySubscriptionsPage = loadable(() => import(/* webpackChunkName: "MySubscriptionsPage" */ 'components/Pages/MySubscriptionsPage'))
const MyWLSubscriptionsPage = loadable(() => import(/* webpackChunkName: "MySubscriptionsPage" */ 'components/Pages/MyWLSubscriptionsPage'))
const AccountDetailsPage = loadable(() => import(/* webpackChunkName: "AccountDetailsPage" */ 'components/Pages/AccountDetailsPage'))
const AccountDetailsTravellerPage = loadable(() => import(/* webpackChunkName: "AccountDetailsTravellerPage" */ 'components/Pages/AccountDetailsTravellerPage'))
const CreditsAndPaymentPage = loadable(() => import(/* webpackChunkName: "CreditsPage" */ 'components/Pages/CreditsPage/CreditsAndPaymentPage'))
const TourTravellerInfoPage = loadable(() => import(/* webpackChunkName: "TourTravellerInfoPage" */ 'components/Pages/TourTravellerInfo/TourTravellerInfoPage'))
const GiftCardConfirmationPage = loadable(() => import(/* webpackChunkName: "GiftCardConfirmationPage" */ 'components/Pages/GiftCardsPage/Confirmation'))
const GiftCardViewPage = loadable(() => import(/* webpackChunkName: "GiftCardViewPage" */ 'components/Pages/GiftCardsPage/View'))
const MapViewPage = loadable(() => import(/* webpackChunkName: "MapViewPage" */ 'components/Pages/MapViewPage'))
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ 'components/Pages/LandingPage/LandingPage'))
const LandingPageLere = loadable(() => import(/* webpackChunkName: "LandingPageLere" */ 'components/Pages/LandingPage/LandingPageLere'))
const TravelInsurancePage = loadable(() => import(/* webpackChunkName: "TravelInsurancePage" */ 'components/Pages/Insurance/LandingPage'))
const TravelInsuranceCoverGeniusPage = loadable(() => import(/* webpackChunkName: "TravelInsuranceCoverGeniusPage" */ 'components/Pages/Insurance/LandingPage/CoverGenius'))
const TravelInsuranceQuote = loadable(() => import(/* webpackChunkName: "TravelInsuranceQuotePage" */ 'components/Pages/Insurance/QuotePage'))
const TravelInsuranceAddCheckout = loadable(() => import(/* webpackChunkName: "TravelInsuranceAddCheckout" */ 'components/Pages/Insurance/AddCheckoutPage'))
const TravelInsuranceDetails = loadable(() => import(/* webpackChunkName: "TravelInsuranceDetailsPage" */ 'components/Pages/Insurance/DetailsPage'))
const TravelInsurancePayment = loadable(() => import(/* webpackChunkName: "TravelInsurancePayment" */ 'components/Pages/Insurance/PaymentPage'))
const EscapesFinderPage = loadable(() => import(/* webpackChunkName: "EscapesFinderPage" */ 'components/Pages/EscapesFinderPage'))
const ConfirmationPage = loadable(() => import(/* webpackChunkName: "ConfirmationPage" */ 'checkout/Components/Confirmation/ConfirmationPage'))
const BNBLLandingPage = loadable(() => import(/* webpackChunkName: "BNBLLandingPage" */ 'components/Pages/BNBLLandingPage'))
const TWCLandingPage = loadable(() => import(/* webpackChunkName: "TourLandingPage" */ 'components/Pages/TWCLandingPage'))
const EWCLandingPage = loadable(() => import(/* webpackChunkName: "EscapeLandingPage" */ 'components/Pages/EWCLandingPage'))
const ContentHub = loadable(() => import(/* webpackChunkName: "ContentHub" */ 'components/Pages/LandingPage/Hub'))
const OfferPage = loadable(() => import(/* webpackChunkName: "OfferPage" */ 'components/OfferPage'))
const BedBankOfferPage = loadable(() => import(/* webpackChunkName: "OfferPage" */ 'components/Pages/BedbankOfferPage'))
const PaginatedOffersPage = loadable(() => import(/* webpackChunkName: "PaginatedOfferPage" */ 'components/Pages/PaginatedOffersPage'))
const TourV2OfferPage = loadable(() => import(/* webpackChunkName: "TourV2OfferPage" */ 'components/Pages/TourV2/OfferPage/TourV2OfferPage'))
const TourV2OfferVariationPage = loadable(() => import(/* webpackChunkName: "TourV2OfferVariationPage" */ 'components/Pages/TourV2/OfferPage/TourV2OfferVariationPage'))
const TourV2SearchPage = loadable(() => import(/* webpackChunkName: "TourV2SearchPage" */ 'components/Pages/TourV2/SearchPage/TourV2SearchPage'))
const HotelsPage = loadable(() => import(/* webpackChunkName: "HotelsPage" */ 'components/Pages/TypesPage/Hotels/HotelsPage'))
const HomesAndVillasVerticalPage = loadable(() => import(/* webpackChunkName: "HomesAndVillasVerticalPage" */ 'components/Pages/HomeAndVillas/LandingPage/HomesAndVillasVerticalPage'))
const HomesAndVillasSearchPage = loadable(() => import(/* webpackChunkName: "HomesAndVillasSearchPage" */ 'components/Pages/HomeAndVillas/Search/HomesAndVillasSearchPage'))
const HomesAndVillasSearchMapPage = loadable(() => import(/* webpackChunkName: "HomesAndVillasSearchMapPage" */ 'components/Pages/HomeAndVillas/Search/HomesAndVillasSearchMapPage'))
const ToursPage = loadable(() => import(/* webpackChunkName: "ToursPage" */ 'components/Pages/TypesPage/ToursPage'))
const UltraLuxVerticalPage = loadable(() => import(/* webpackChunkName: "UltraLuxVerticalPage" */ 'components/Pages/TypesPage/UltraLux/UltraLuxVerticalPage'))
const LuxBundlesPage = loadable(() => import(/* webpackChunkName: "LuxBundlesVerticalPage" */ 'components/Pages/TypesPage/LuxBundlesPage'))
const DestinationProductPage = loadable(() => import(/* webpackChunkName: "DestinationProductPage" */ 'components/Pages/DestinationProductPage/DestinationProductPage'))
const VFFLandingPage = loadable(() => import(/* webpackChunkName: "VFFLandingPage" */ 'components/Pages/PromotionalPages/VFFLandingPage'))
const KrisFlyerLandingPage = loadable(() => import(/* webpackChunkName: "KrisFlyerLandingPage" */ 'components/Pages/PromotionalPages/KrisFlyerLandingPage'))
const GiftCardsPageContainer = loadable(() => import(/* webpackChunkName: "GiftCardsPage" */ 'components/Pages/GiftCardsPage'))
const KlarnaLandingPage = loadable(() => import(/* webpackChunkName: "KlarnaLandingPage" */ 'components/Pages/PromotionalPages/KlarnaLandingPage'))
const AfterPayLandingPage = loadable(() => import(/* webpackChunkName: "AfterPayLandingPage" */ 'components/Pages/PromotionalPages/AfterPayLandingPage'))
const HoolahLandingPage = loadable(() => import(/* webpackChunkName: "HoolahLandingPage" */ 'components/Pages/PromotionalPages/HoolahLandingPage'))
const RedirectToShopbackPage = loadable(() => import(/* webpackChunkName: "RedirectToShopbackPage" */ 'components/Pages/PromotionalPages/RedirectToShopbackPage'))
const AtomeLandingPage = loadable(() => import(/* webpackChunkName: "AtomeLandingPage" */ 'components/Pages/PromotionalPages/AtomeLandingPage'))
const WaaveLandingPage = loadable(() => import(/* webpackChunkName: "WaaveLandingPage" */ 'components/Pages/PromotionalPages/WaaveLandingPage'))
const LastMinuteEscapesPage = loadable(() => import(/* webpackChunkName: "LastMinuteEscapesPage" */ 'components/Pages/TypesPage//LastMinuteEscapes/LastMinuteEscapesPage'))
const AccountPage = loadable(() => import(/* webpackChunkName: "AccountLandingPage" */ 'components/Pages/AccountPage/AccountLandingPage'))
const ExperienceOfferPage = loadable(() => import(/* webpackChunkName: "ExperienceOfferPage" */ 'components/Experiences/ExperienceOfferPage/ExperienceOfferPage'))
const ExperiencesPage = loadable(() => import(/* webpackChunkName: "ExperiencesPage" */ 'components/Experiences/ExperiencesPage/ExperiencesPage'))
const ExperienceSearchMapPage = loadable(() => import(/* webpackChunkName: "ExperienceSearchMapPage" */ 'components/Experiences/ExperiencesSearch/ExperienceSearchMapPage'))
const CruisesPage = loadable(() => import(/* webpackChunkName: "CruisesPage" */ 'components/Cruises/CruisesPage/CruisesPage'))
const CruiseSearchPage = loadable(() => import(/* webpackChunkName: "CruiseSearchPage" */ 'components/Cruises/SearchPage/CruiseSearchPage'))
const CruiseOfferPage = loadable(() => import(/* webpackChunkName: "CruiseOfferPage" */ 'components/Cruises/CruiseOfferPage/CruiseOfferPage'))
const CruiseLineLandingPage = loadable(() => import(/* webpackChunkName: "CruiseLineLandingPage" */ 'components/Cruises/CruiseLineLandingPage/CruiseLineLandingPage'))
const CruiseDealPage = loadable(() => import(/* webpackChunkName: "CruiseDealPage" */ 'components/Cruises/CruiseDealPage/CruiseDealPage'))
const ExperienceSearchPage = loadable(() => import(/* webpackChunkName: "ExperienceSearchPage" */ 'components/Experiences/ExperiencesSearch/ExperienceSearchPage'))
const TopPicksForMePage = loadable(() => import(/* webpackChunkName: "TopPicksForMePage" */ 'components/Recommendations/TopPicksForMe/TopPicksForMePage'))
const RefereeLandingPage = loadable(() => import(/* webpackChunkName: "RefereeLandingPage" */ '../../../../content/pages/RefereeLanding'))
const GoogleHotelAdPage = loadable(() => import(/* webpackChunkName: "GoogleHotelAdPage" */ '../../../../marketing/pages/GoogleHotelAdPage'))
const CheckoutPageRoutes = loadable(() => import(/* webpackChunkName: "CheckoutPageRoutes" */ 'checkout/routes'))
const CheckoutRestoreCartPage = loadable(() => import(/* webpackChunkName: "CheckoutRestoreCartPage" */ 'checkout/Pages/RestoreCartPage'))
const CheckoutGatewayPage = loadable(() => import(/* webpackChunkName: "CheckoutGatewayPage" */ 'checkout/Pages/GatewayPage'))
const AncillaryPaymentPage = loadable(() => import(/* webpackChunkName: "AncillaryPaymentPage" */ 'checkout/Pages/AncillaryPaymentPage'))
const AncillaryChangeReservationPage = loadable(() => import(/* webpackChunkName: "ChangeReservationPage" */ 'checkout/Pages/AncillaryChangeReservationPage'))
const ExperienceVoucher = loadable(() => import(/* webpackChunkName: "ExperienceVoucher" */ 'components/Pages/ExperienceVoucher'))
const PropertyPage = loadable(() => import(/* webpackChunkName: "PropertyPage" */ '../../../../marketing/pages/PropertyPage'))
const SplashPage = loadable(() => import(/* webpackChunkName: "SplashPage" */ 'components/Pages/SplashPage'))
const RedeemGifted = loadable(() => import(/* webpackChunkName: "RedeemGifted" */ 'components/Pages/RedeemGifted'))
const AuCompetitionsPage = loadable(() => import(/* webpackChunkName: "AuCompetitionsPage" */ '../../../../marketing/pages/AuCompetitionPage'))
const IntlCompetitionsPage = loadable(() => import(/* webpackChunkName: "IntlCompetitionsPage" */ '../../../../marketing/pages/INTLCompetitionPage'))
const ukCompetitionsPage = loadable(() => import(/* webpackChunkName: "UKCompetitionsPage" */ '../../../../marketing/pages/UKCompetitionPage'))
const LETVS6CompetitionPage = loadable(() => import(/* webpackChunkName: "CompetitionPage" */ '../../../../marketing/pages/LETVS6CompetitionPage'))
const HolidayTypeLandingPage = loadable(() => import(/* webpackChunkName: "HolidayTypeLandingPage" */ 'components/Pages/HolidayTypeLandingPage'))
const CarHirePage = loadable(() => import(/* webpackChunkName: "CarHirePage" */ 'components/CarHire/CarHirePage/CarHirePage'))
const CarHireSearchPage = loadable(() => import(/* webpackChunkName: "CarHireSearchPage" */ 'components/CarHire/CarHireSearchPage/CarHireSearchPage'))
const HomePage = loadable(() => import(/* webpackChunkName: "HomePage" */ '../../../../home/pages/HomePage/HomePage'))
const ContentfulWebPage = loadable(() => import(/* webpackChunkName: "ContentfulWebPage" */'../../../../marketing/pages/ContentfulWebPage/ContentfulWebPage'))
const LuxAwardPage = loadable(() => import(/* webpackChunkName: "ContentfulWebPage" */'../../../../marketing/pages/ContentfulWebPage/LuxAward/LuxAwardPage'))
const LETVLandingPage = loadable(() => import(/* webpackChunkName: "LETVLandingpage" */'../../../../marketing/pages/LETVLandingPage'))
const LETVEpisodePage = loadable(() => import(/* webpackChunkName: "LETVEpisodePage" */'../../../../marketing/pages/LETVEpisodePage'))
const LuxPlusLandingPage = loadable(() => import(/* webpackChunkName: "LuxPlusLandingPage" */ 'luxPlus/pages/LuxPlusLandingPage'))
const LuxPlusAccountPage = loadable(() => import(/* webpackChunkName: "LuxPlusAccountPage" */ 'luxPlus/pages/LuxPlusAccountPage'))
const LuxLoyaltyLandingPage = loadable(() => import(/* webpackChunkName: "LuxLoyaltyLandingPage" */ 'luxLoyalty/pages/LuxLoyaltyLandingPageContainer'))
const ResetPasswordPage = loadable(() => import(/* webpackChunkName: "ResetPasswordPage" */ 'components/Pages/ResetPasswordPage/ResetPasswordPage'))
const FlightDealLandingPage = loadable(() => import(/* webpackChunkName: "FlightDealLandingPage" */ 'components/Flights/FlightDealLandingPage/FlightDealLandingPage'))
const TravelPreferencesPage = loadable(() => import(/* webpackChunkName: "TravelPreferencesPage" */ 'components/Pages/AccountTravelPreferencesPage/TravelPreferencesPage'))
const AgentHubHomePage = loadable(() => import(/* webpackChunkName: "AgentHubHomepage" */ 'agentHub/pages/AgentHubHomepage'))
const AgentHubAccountSetupCompleted = loadable(() => import(/* webpackChunkName: "AgentHubAccountSetupCompleted" */ 'agentHub/pages/AgentHubAccountSetup/AgentHubAccountSetupCompleted'))
const AgentHubToursPage = loadable(() => import(/* webpackChunkName: "AgentHubToursPage" */ 'agentHub/pages/AgentHubToursPage'))
const SkyCheckConfirmationPage = loadable(() => import(/* webpackChunkName: "SkyCheckConfirmationPage" */ 'components/Pages/EmailConfirmedPage/SkyCheckConfirmation/SkyCheckConfirmationPage'))
const BusinessTravellerFaqPage = loadable(() => import(/* webpackChunkName: "BusinessTravellerFaqPage" */ 'businessTraveller/pages/faq/BusinessTravellerFaqPage'))
const TailorMadeTourRequestPage = loadable(() => import(/* webpackChunkName: "TailorMadeTourRequestPage" */ 'components/Pages/TailorMadeTour/TailorMadeTourRequestPage'))
const TailorMadeTourRequestConfirmationPage = loadable(() => import(/* webpackChunkName: "TailorMadeTourRequestConfirmationPage" */ 'components/Pages/TailorMadeTour/TailorMadeTourRequestConfirmationPage'))
const SecurePaymentPage = loadable(() => import(/* webpackChunkName: "SecurePaymentPage" */ 'components/Pages/SecurePaymentPage'))
const WhitelabelHomePage = loadable(() => import(/* webpackChunkName: "WhitelabelHomePage" */ 'whitelabel/pages/WhitelabelHomePage'))
const TravelCompanionsPage = loadable(() => import(/* webpackChunkName: "TravelCompanionPage" */ 'components/Pages/TravelCompanionsPage'))
const UserCartPage = loadable(() => import(/* webpackChunkName: "UserCartPage" */ 'components/Pages/UserCartPage/UserCartPage'))
const TourCataloguePage = loadable(() => import(/* webpackChunkName: "TourCatalogPage" */ 'components/Pages/TourV2/TourCataloguePage'))

const promotionalPages: Record<string, LoadableComponent<any>> = {
  VFFLandingPage,
  KrisFlyerLandingPage,
  GiftCardsPageContainer,
  KlarnaLandingPage,
  AfterPayLandingPage,
  HoolahLandingPage,
  RedirectToShopbackPage,
  AtomeLandingPage,
  WaaveLandingPage,
}

const onlyTour: Array<App.LEContractedOfferType> = [OFFER_TYPE_TOUR]
const onlyLastMinute: Array<App.LEContractedOfferType> = [OFFER_TYPE_LAST_MINUTE]
const onlyHotel: Array<App.LEContractedOfferType> = [OFFER_TYPE_HOTEL]
const mapPageFilters: App.OfferListFilters = {
  limit: TYPE_PAGE_LIMIT,
}
const ultraLuxOfferTypes: Array<App.LEContractedOfferType> = [OFFER_TYPE_HOTEL, 'tactical_ao_hotel']
const ultraLuxMapPageFilters: App.OfferListFilters = {
  ...mapPageFilters,
  propertySubCategories: ['Ultra Lux'],
}

interface Props {
  currentRegionCode: string
  standaloneFlightsEnabled: boolean
  standaloneCruiseEnabled: boolean
  isLuxPlusEnabled: boolean
  isLuxLoyaltyEnabled: boolean
  isStandaloneAirportTransfersEnabled: boolean
  tenant: string
}

function PageRoutes(props: Props) {
  const {
    currentRegionCode,
    standaloneFlightsEnabled,
    standaloneCruiseEnabled,
    isLuxPlusEnabled,
    isLuxLoyaltyEnabled,
    isStandaloneAirportTransfersEnabled,
  } = props
  const match = useRouteMatch<{ regionCode: string }>()

  const hasSetupAgentHubAccount = useAppSelector(state => state.agentHub.agentHubAccount.account?.status === 'onboarded')

  return (
    <Switch>
      {/* This blocks access to all pages but the onboarding for agent hub until the user has signed up */}
      {config.agentHub.isEnabled && !hasSetupAgentHubAccount && <AgentHubGuestPageRoutes />}

      {/* Tour v1 redirects */}
      {Object.keys(config.toursV1Redirects).map(tourV1 =>
        <RedirectWithStatus
          key={tourV1}
          code={301}
          from={buildPath(match, `/offer/${tourV1}`)}
          to={`/tour/${config.toursV1Redirects[tourV1]}`}
          withSearchAndHash={false}
        />,
      )}

      {/* Offer list Pages */}
      {config.agentHub.isEnabled && <Route exact path={buildPath(match, `/${OFFER_TYPE_TOUR_SLUG}`)} component={AgentHubToursPage} />}
      <Route exact path={buildPath(match, `/${OFFER_TYPE_TOUR_SLUG}`)} component={ToursPage} />
      <Route path={buildPath(match, `/${OFFER_TYPE_TOUR_SLUG}/map-view`)}>
        <MapViewPage
          listViewPath={`/${OFFER_TYPE_TOUR_SLUG}`}
          offerTypes={onlyTour}
        />
      </Route>
      <Route exact path={buildPath(match, `/${OFFER_TYPE_HOTEL_SLUG}`)} component={HotelsPage} />
      <Route path={buildPath(match, `/${OFFER_TYPE_HOTEL_SLUG}/map-view`)}>
        <MapViewPage listViewPath={`/${OFFER_TYPE_HOTEL_SLUG}`}
          offerTypes={onlyHotel}
          filters={mapPageFilters}
        />
      </Route>
      <Route exact path={buildPath(match, `/${OFFER_TYPE_BUNDLE_AND_SAVE_SLUG}`)} component={LuxBundlesPage} />
      {config.ULTRALUX_ENABLED && <Route exact path={buildPath(match, `/${PRODUCT_TYPE_ULTRALUX_SLUG}`)} component={UltraLuxVerticalPage} />}
      {config.ULTRALUX_ENABLED && <Route path={buildPath(match, `/${PRODUCT_TYPE_ULTRALUX_SLUG}/map-view`)}>
        <MapViewPage
          listViewPath={`/${PRODUCT_TYPE_ULTRALUX_SLUG}`}
          offerTypes={ultraLuxOfferTypes}
          filters={ultraLuxMapPageFilters}
          heading={<>Explore our <i>ultra lux</i> offers</>}
        />
      </Route>}
      {config.EXPERIENCES_STANDLONE_ENABLED && <Route exact path={buildPath(match, '/experiences')} component={ExperiencesPage} />}

      {/* Cruises */}
      {standaloneCruiseEnabled && <Route exact path={buildPath(match, '/cruises')} component={CruisesPage} />}
      <Route path={buildPath(match, '/cruises/departures/:departureLocation')} component={CruiseLocationLandingPage} />
      <Route path={buildPath(match, '/cruises/destinations/:destinationLocation')} component={CruiseLocationLandingPage} />
      <Route path={buildPath(match, '/cruises/ships/:shipNames')} component={CruiseShipsLandingPage} />
      {standaloneCruiseEnabled && <Route exact path={buildPath(match, '/cruises/deal/:slug')} component={CruiseDealPage} />}
      {standaloneCruiseEnabled && <Route exact path={buildPath(match, '/cruises/:offerId')} component={CruiseOfferPage} />}
      {standaloneCruiseEnabled && <Route exact path={buildPath(match, '/cruises/:offerSlug/:offerId')} component={CruiseOfferPage} />}
      {standaloneCruiseEnabled && <Route exact path={buildPath(match, '/cruise-line/:cruiseLineSlug')} component={CruiseLineLandingPage} />}

      {/* Car Hire Pages */}
      {config.CARHIRE_ENABLED && config.CARHIRE_REGIONS.split(',').includes(currentRegionCode) && <Route exact path={buildPath(match, '/car-hire')} component={CarHirePage} />}

      {/* Offer pages */}
      <Route exact path={buildPath(match, `/${PRODUCT_TYPE_HOME_AND_VILLAS_SLUG}`)} component={HomesAndVillasVerticalPage} />
      <Route exact path={buildPath(match, '/offer/:offerId')}>
        {({ match }) => <OfferPage key={match?.params.offerId} />}
      </Route>
      <Route path={buildPath(match, '/offer/:offerSlug/:offerId')}>
        {({ match }) => <OfferPage key={match?.params.offerId} />}
      </Route>
      {config.EXPERIENCES_STANDLONE_ENABLED && <Route exact path={buildPath(match, '/experience/:id')}>
        {({ match }) => <ExperienceOfferPage key={match?.params.offerId} />}
      </Route>}
      {config.EXPERIENCES_STANDLONE_ENABLED && <Route path={buildPath(match, '/experience/:slug/:id')}>
        {({ match }) => <ExperienceOfferPage key={match?.params.offerId} />}
      </Route>}
      <Route path={buildPath(match, '/partner/:offerSlug/:offerId')}>
        {({ match }) => <BedBankOfferPage key={match?.params.offerId} />}
      </Route>
      <Route exact path={buildPath(match, `/${OFFER_TYPE_LAST_MINUTE_SLUG}`)} component={LastMinuteEscapesPage} />
      <Route path={buildPath(match, `/${OFFER_TYPE_LAST_MINUTE_SLUG}/map-view`)}>
        <MapViewPage
          listViewPath={`/${OFFER_TYPE_LAST_MINUTE_SLUG}`}
          offerTypes={onlyLastMinute}
          filters={mapPageFilters}
        />
      </Route>
      <Route path={buildPath(match, '/page/:pageNum')} component={PaginatedOffersPage} />
      <Route path={buildPath(match, '/map-view')}>
        <MapViewPage
          offerTypes={FRONT_PAGE_MAP_VIEW_OFFERS_TYPES(currentRegionCode)} listViewPath="/"
          filters={mapPageFilters}
        />
      </Route>
      // If brand defines a particular default homepage, redirect "/" to that location
      {
        config.defaultHomePage && (
          <Route
            exact
            path={buildPath(match, '/')}
            component={({ location }: RouteComponentProps) => (
              <RedirectWithStatus
                code={302}
                to={location.pathname.replace(/$/, config.defaultHomePage)}
              />
            )
            }
          />
        )
      }

      {config.agentHub.isEnabled && <Route exact path={buildPath(match, '/')} component={AgentHubHomePage} />}
      {config.WHITELABEL_HOME_PAGE_ENABLED && <Route exact path={buildPath(match, '/')} component={WhitelabelHomePage} />}

      <Route exact path={buildPath(match, '/')} component={HomePage} />
      <RedirectWithStatus code={301} from={buildPath(match, '/villas')} to={buildPath(match, '/holidays/homes-and-villas')} />

      {/* Support Pages */}
      {config.SUPPORT_ENABLED && <Route path={buildPath(match, '/support')} component={SupportPage} />}

      {/* Sales Team Contact Page */}
      <Route path={buildPath(match, '/request-video-chat/:offerId/:packageId')} component={VideoChatPage} />
      {config.ENABLE_LETV_S6_SPIN_COMPETITION_PAGE && <Route path={buildPath(match, '/tv/season-6/competition')} component={LETVS6CompetitionPage} />}

      {/* LuxPlus */}
      {isLuxPlusEnabled &&
        <Route path={buildPath(match, `/${LUXURY_PLUS_PATHS.DEFAULT_LANDING_PAGE}`)} component={LuxPlusLandingPage} />
      }
      {isLuxPlusEnabled &&
        <RedirectWithStatus code={301} from={buildPath(match, '/luxplus')} to={buildPath(match, `/${LUXURY_PLUS_PATHS.LANDING_PAGE}`)}/>
      }

      {/* LuxLoyalty */}
      {isLuxLoyaltyEnabled &&
        <Route path={buildPath(match, `/${LUX_LOYALTY.LANDING_PAGE}`)} component={LuxLoyaltyLandingPage} />
      }
      {isLuxLoyaltyEnabled &&
        <RedirectWithStatus code={301} from={buildPath(match, '/society')} to={buildPath(match, `/${LUX_LOYALTY.LANDING_PAGE}`)}/>
      }
      {isLuxLoyaltyEnabled &&
        <RedirectWithStatus code={301} from={buildPath(match, '/société')} to={buildPath(match, `/${LUX_LOYALTY.LANDING_PAGE}`)}/>
      }

      {/* Search Pages */}
      <Route path={buildPath(match, '/search-fullscreen')} component={HotelSearchFullScreenPage} />
      <Route exact path={buildPath(match, '/search')} component={HotelSearchPage} />
      <Route exact path={buildPath(match, '/search/map')} component={HotelSearchMapPage} />
      <Route exact path={buildPath(match, '/search/homes-and-villas')} component={HomesAndVillasSearchPage} />
      <Route exact path={buildPath(match, '/search/homes-and-villas/map')} component={HomesAndVillasSearchMapPage} />
      {config.EXPERIENCES_STANDLONE_ENABLED && <Route exact path={buildPath(match, '/search/experiences')} component={ExperienceSearchPage} />}
      {config.EXPERIENCES_STANDLONE_ENABLED && <Route exact path={buildPath(match, '/search/experiences/map')} component={ExperienceSearchMapPage} />}
      {config.CRUISES_STANDLONE_ENABLED && <Route exact path={buildPath(match, '/search/cruises')} component={CruiseSearchPage} />}
      {config.CARHIRE_ENABLED && config.CARHIRE_REGIONS.split(',').includes(currentRegionCode) && <Route exact path={buildPath(match, '/search/car-hire')} component={CarHireSearchPage} />}

      <RedirectWithStatus code={301} from={buildPath(match, '/amex')} to={buildPath(match, '/')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/refund-policy')} to={buildPath(match, '/booking-policies')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/frequently-asked-questions')} to={buildPath(match, '/support')} />

      {/* Account pages */}
      <RedirectWithStatus code={301} from={buildPath(match, '/account/my-destinations')} to={buildPath(match, '/account')} />
      <AuthRoute exact path={buildPath(match, '/account')} component={AccountPage} />
      <AuthRoute exact path={buildPath(match, '/account/credits-payments')} component={CreditsAndPaymentPage} />
      <AuthRoute showPopup path={buildPath(match, '/account/my-escapes/experiences/:experienceItemId/voucher')} component={ExperienceVoucher} />
      <AuthRoute showPopup exact path={buildPath(match, '/account/my-escapes/order/:orderId')} component={OrderPage} />
      <AuthRoute exact path={buildPath(match, '/account/travel-preferences')} component={TravelPreferencesPage} />
      <AuthRoute exact path={buildPath(match, '/account/travel-companions')} component={TravelCompanionsPage} />
      {isStandaloneAirportTransfersEnabled &&
        <AuthRoute exact path={buildPath(match, '/account/my-escapes/order/:orderId/airport-transfers')} component={AirportTransferPage} />
      }

      {/* Apps are still pointing to /my-escapes, so redirect to the updated route */}
      <RedirectWithStatus code={301} from={buildPath(match, '/my-escapes/:modalType?')} to={buildPath(match, '/account/my-escapes/:modalType?')} />
      <AuthRoute showPopup path={buildPath(match, '/account/my-escapes/:tab(upcoming|cancelled|past|approval-requests)?')} component={MyEscapesPage} />
      <AuthRoute exact path={buildPath(match, '/account/details/traveller')} component={AccountDetailsTravellerPage} />
      <AuthRoute exact path={buildPath(match, '/account/details')} component={AccountDetailsPage} showPopup />
      <AuthRoute path={buildPath(match, '/booking-form/:orderId?')} component={TourTravellerInfoPage} />
      <AuthRoute showPopup exact path={buildPath(match, '/review/:orderId?')} component={UserReviews} />
      <Route exact path={buildPath(match, '/reset-password/:resetPasswordToken')} component={ResetPasswordPage} />
      {isLuxPlusEnabled && <AuthRoute showPopup exact path={buildPath(match, `/${LUXURY_PLUS_PATHS.ACCOUNT_MANAGEMENT_PAGE}`)} component={LuxPlusAccountPage} />}

      {/* hidden preference centre page */}
      <AuthRoute showPopup path={buildPath(match, '/account/hidden-communication-preferences')} component={config.BRAND === LUXURY_ESCAPES ? MySubscriptionsPage : MyWLSubscriptionsPage} />

      {/* Subscriptions Pages */}
      <AuthRoute path={buildPath(match, '/account/communication-preferences')} component={PreferenceCentrePage} />
      <RedirectWithStatus code={301} from={buildPath(match, '/my-subscriptions')} to={buildPath(match, '/account/communication-preferences')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/account/my-subscriptions')} to={buildPath(match, '/account/communication-preferences')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/account/subscriptions')} to={buildPath(match, '/account/communication-preferences')} />

      <RedirectWithStatus code={301} exact from={buildPath(match, '/account/payment-cards')} to={buildPath(match, '/account/credits-payments')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/account-details')} to={buildPath(match, '/account/details')} />

      {/* Insurance pages */}
      {isCoverGeniusEnabled(currentRegionCode) && <AuthRoute showPopup path={buildPath(match, '/travel-protection/quote')} component={TravelInsuranceQuote} />}
      {isCoverGeniusEnabled(currentRegionCode) && <AuthRoute showPopup path={buildPath(match, '/travel-protection/add')} component={TravelInsuranceAddCheckout} />}
      {isCoverGeniusEnabled(currentRegionCode) && <AuthRoute showPopup path={buildPath(match, '/travel-protection/details')} component={TravelInsuranceDetails} />}
      {isCoverGeniusEnabled(currentRegionCode) && <AuthRoute showPopup path={buildPath(match, '/travel-protection/payment')} component={TravelInsurancePayment} />}
      <RedirectWithStatus code={301} from={buildPath(match, '/insurance/checkout')} to={buildPath(match, '/travel-protection/quote')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/insurance/confirmation')} to={buildPath(match, '/booking/confirmation')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/insurance')} to={buildPath(match, '/travel-protection')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/insurance/xcover')} to={buildPath(match, '/travel-protection/xcover')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/insurance/quote')} to={buildPath(match, '/travel-protection/quote')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/insurance/add')} to={buildPath(match, '/travel-protection/add')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/insurance/details')} to={buildPath(match, '/travel-protection/details')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/insurance/payment')} to={buildPath(match, '/travel-protection/payment')} />

      {/* Gift card pages */}
      <RedirectWithStatus code={301} from={buildPath(match, '/giftcards')} to={buildPath(match, '/gift-cards')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/giftcard')} to={buildPath(match, '/gift-cards')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/gift-card')} to={buildPath(match, '/gift-cards')} exact />
      <AuthRoute path={buildPath(match, '/gift-cards/confirmation')} component={GiftCardConfirmationPage} />
      <AuthRoute showPopup path={buildPath(match, '/gift-card/:code')} component={GiftCardViewPage} />

      {/* Booking pages */}
      <AuthRoute showPopup path={buildPath(match, '/booking/confirmation')} component={ConfirmationPage} />
      <AuthRoute showPopup path={buildPath(match, '/bb/booking/confirmation')} component={ConfirmationPage} />
      <RedirectWithStatus code={301} from={buildPath(match, '/confirmation/:orderId')} to={buildPath(match, '/booking/confirmation?orderId=:orderId')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/bb/booking/confirmation')} to={buildPath(match, '/booking/confirmation')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/bb/booking/:step')} to={buildPath(match, '/booking/:step')} />

      {/* Redeem gift hotel and experience */}
      <Route exact path={buildPath(match, '/redeem/:redeemCode/:orderId/:offerId')} component={RedeemGifted} />

      {/* TourV2Pages */}
      <Route exact path={buildPath(match, '/tour/:offerId')}>
        {({ match }) => <TourV2OfferPage key={match?.params.offerId} />}
      </Route>

      <Route exact path={buildPath(match, '/tour/:offerId/:variationId')} component={TourV2OfferVariationPage} />

      <Route exact path={buildPath(match, '/search/tours')} component={TourV2SearchPage} />

      {/* Tour signature series contentful redirects */}
      <RedirectWithStatus code={301} from={buildPath(match, '/signature-series.p1')} to={buildPath(match, '/campaign/signature-series')} />

      {/* Tailor-Made Tours */}
      {config.TAILOR_MADE_TOURS_ENABLED && config.TAILOR_MADE_TOURS_REGIONS && isEnabledForFeature(config.TAILOR_MADE_TOURS_REGIONS, currentRegionCode) && (
        <Route exact path={buildPath(match, '/tailor-made-tours/requests/:requestId')} component={TailorMadeTourRequestConfirmationPage} />
      )}
      {config.TAILOR_MADE_TOURS_ENABLED && config.TAILOR_MADE_TOURS_REGIONS && isEnabledForFeature(config.TAILOR_MADE_TOURS_REGIONS, currentRegionCode) && (
        <Route exact path={buildPath(match, '/tailor-made-tours/requests')} component={TailorMadeTourRequestPage} />
      )}

      <Route exact path={buildPath(match, '/tours/tours-catalogue')} component={TourCataloguePage} />

      {config.hasReferralProgram && (
        <Route exact path={buildPath(match, '/refer')} component={ReferPage} />
      )}
      {config.hasReferralProgram && (
        <Route path={buildPath(match, '/refer/:referralCode')} component={RefereeLandingPage} />
      )}

      <Route path={buildPath(match, '/google-hotel-ads')} component={GoogleHotelAdPage} />
      <RedirectWithStatus code={302} from={buildPath(match, '/property/ayodya-resort-bali-bedbank:6486e2f1-958e-4f91-a5b0-481205904898')} to={buildPath(match, '/offer/ayodya-resort-bali-nusa-dua-indonesia-asia/0062y00000JN0RvAAL')} />
      <RedirectWithStatus code={302} from={buildPath(match, '/abercrombie-and-kent-tours.p1')} to={buildPath(match, '/tours')} />

      <Route path={buildPath(match, '/property/:propertyNameAndId')} component={PropertyPage} />
      <Route exact path={buildPath(match, '/tv/season-6/:episode')} component={LETVEpisodePage} />
      <Route exact path={buildPath(match, '/tv/season-6')} component={LETVLandingPage} />
      {config.ENABLE_AU_COMPETITION && <Route path={buildPath(match, '/hunter-valley-competition')} component={AuCompetitionsPage} />}
      {config.ENABLE_INTL_COMPETITION && <Route path={buildPath(match, '/spin-the-wheel-competition')} component={IntlCompetitionsPage} />}
      <Route path={buildPath(match, '/NHCollectionDubaiThePalm')} component={ukCompetitionsPage} />
      <RedirectWithStatus code={301} from={buildPath(match, '/citi')} to={buildPath(match, '/save-with-citi.p1')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/terms')} to={buildPath(match, '/terms-and-conditions')} />

      <Route path={buildPath(match, '/sharethis/verify')} component={(): null => {
        const mainUrl = `https://platform-api.sharethis.com/js/sharethis.js#property=${config.GOOGLE_APP_ID}`
        const script = document.createElement('script')
        script.setAttribute('id', 'sharethis-js')
        script.src = mainUrl
        script.async = true
        document.body.appendChild(script)
        return null
      }}
      />

      {/* Content hub pages */}
      {config.CONTENT_HUB_ENABLED && config.contentHub &&
        <RedirectWithStatus code={301} from={buildPath(match, '/pages/:slug')} to={buildPath(match, '/:slug.p1')} />
      }
      {config.CONTENT_HUB_ENABLED && config.contentHub &&
        <Route exact path={buildPath(match, '/:slug.p1')} component={ContentHub} />
      }

      {config.staticPages.map(sp => (
        sp.url?.to && <Route
          exact
          key={sp.url.to}
          path={buildPath(match, sp.url.to)}
          render={props => {
            if (sp.type === 'bestPrice') {
              return <BestPricePage />
            } else if (sp.type === 'aboutUs') {
              return <AboutUsPage />
            } else if (sp.type === 'careersAndCulture') {
              return <CareersPage />
            }
            else if (sp.type === 'termsAndConditions') {
              return <LegalPages slug="terms-and-conditions" location={props.location} />
            } else if (sp.type === 'luxPlusTermsAndConditions') {
              return <LegalPages slug={LUXURY_PLUS_PATHS.TERMS_AND_CONDITIONS_PAGE} location={props.location} />
            } else if (sp.type === 'societeTermsAndConditions') {
              return <LegalPages slug="societe-terms-and-conditions" location={props.location} />
            } else if (sp.type === 'societeFAQs') {
              return <LegalPages slug="societe-frequently-asked-questions" location={props.location} />
            } else if (sp.type === 'refundPolicy') {
              return <LegalPages slug="refund-policy" location={props.location} />
            } else if (sp.type === 'news') {
              return <NewsPage slug="news" location={props.location} />
            } else if (sp.type === 'privacyPolicy') {
              return <LegalPages slug="privacy-policy" location={props.location} />
            } else if (sp.type === 'giftcardTermsAndConditions') {
              return <LegalPages slug="giftcards-terms" location={props.location} />
            } else if (sp.type === 'flights') {
              return <LegalPages slug="flights-policies" location={props.location} />
            } else if (sp.type === 'frequentFlyerPrograms') {
              return <FrequentFlyerPage slug="frequent-flyer-programs" location={props.location} />
            } else if (sp.type === 'specialProductsPromotions') {
              return <LegalPages slug="promotions" location={props.location} />
            } else if (sp.type === 'bookingPolicies') {
              return <LegalPages slug="booking-policies" location={props.location} />
            } else if (sp.type === 'bestPriceTerms') {
              return <LegalPages slug="best-price-guarantee-terms" location={props.location} />
            } else if (sp.type === 'feeSchedule') {
              return <LegalPages slug="fee-schedule" location={props.location} />
            } else if (sp.type === 'travelInsurance') {
              return <TravelInsurancePage />
            } else if (sp.type === 'travelInsuranceCoverGenius') {
              return <TravelInsuranceCoverGeniusPage />
            } else if (sp.type === 'bnblLanding') {
              return <BNBLLandingPage />
            } else if (sp.type === 'twcLanding') {
              return <TWCLandingPage />
            } else if (sp.type === 'ewcLanding') {
              return <EWCLandingPage />
            } else if (sp.type === 'businessFaq') {
              return <BusinessTravellerFaqPage />
            } else if (sp.type === 'affiliatePage') {
              return <ContentfulWebPage />
            } else {
              return <StaticPage type={sp.type} title={sp.text} />
            }
          }}
        />
      ))}

      {/* Promotional Pages */}
      {!standaloneFlightsEnabled && <Route path={buildPath(match, '/flights')} component={FlightsLandingPage} />}
      {standaloneFlightsEnabled && <Route exact path={buildPath(match, '/flights/:dealCategory?')} component={FlightsSearchLandingPage} />}
      {standaloneFlightsEnabled && <Route path={buildPath(match, '/flights-search')} component={FlightsSearchPage} />}
      {standaloneFlightsEnabled && <Route path={buildPath(match, '/flights-search-results')} component={FlightsSearchResultsPage} />}
      {standaloneFlightsEnabled && <Route path={buildPath(match, '/flights-search-results-multi-city')} component={FlightsSearchMultiCityResultsPage} />}
      <Route path={buildPath(match, '/flight/deal/:id')} component={FlightDealLandingPage} />
      <Route path={buildPath(match, '/escapes-finder')} component={EscapesFinderPage} />
      <RedirectWithStatus from={buildPath(match, '/kris-flyer')} to={buildPath(match, '/krisflyer')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/disneyland-offers')} to={buildPath(match, '/experience/led%3A0062y00000Gui6KAAR')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/disneyland-offers-test')} to={buildPath(match, '/experience/led%3A0062y000009EEOEAA4')} />

      {config.promotionalPages.map(pp => {
        const PromotionalComponent = promotionalPages[pp.component]
        return <Route
          key={pp.url}
          path={buildPath(match, pp.url)}
        >
          <PromotionalComponent />
        </Route>
      })}
      {config.holidayPages &&
        <RedirectWithStatus code={301} from={buildPath(match, '/holidays/:slug')} to={buildPath(match, '/:slug.h1')} />
      }
      {config.holidayPages &&
        <Route path={buildPath(match, '/:slug.h1')} component={(props: ComponentProps<typeof LandingPage>) => <LandingPage {...props} typeOfPage={TYPE_OF_PAGE_HOLIDAY} />} />
      }
      {config.destinationPages &&
        <RedirectWithStatus code={301} from={buildPath(match, '/:slug.d1')} to={buildPath(match, '/destination/:slug')} />
      }
      {config.destinationPages &&
        <Route path={buildPath(match, '/:slug.d1.lere')} component={(props: ComponentProps<typeof LandingPageLere>) => <LandingPageLere {...props} typeOfPage={TYPE_OF_PAGE_DESTINATION} />} />
      }
      <RedirectWithStatus code={301} from={buildPath(match, '/campaign/lux-list-travel-travel-awards-voting')} to={buildPath(match, '/campaign/lux-list-travel-awards')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/campaign/lux-list-travel-travel-awards-voting-uk')} to={buildPath(match, '/campaign/lux-list-travel-awards-uk')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/campaign/lux-list-travel-travel-awards-voting-us')} to={buildPath(match, '/campaign/lux-list-travel-awards-us')} />
      {/* Redirect due to wrong URL sent out */}
      <RedirectWithStatus code={301} from={buildPath(match, '/campaign/singapore/competition-april')} to={buildPath(match, '/campaign/competition-april')} />
      <Route exact path={buildPath(match, '/campaign/lux-list-travel-awards')} component={LuxAwardPage} />
      <Route exact path={buildPath(match, '/campaign/lux-list-travel-awards-prizes')} component={LuxAwardPage} />
      <Route exact path={buildPath(match, '/campaign/lux-list-travel-awards-us')} component={LuxAwardPage} />
      <Route exact path={buildPath(match, '/campaign/lux-list-travel-awards-prizes-us')} component={LuxAwardPage} />
      <Route exact path={buildPath(match, '/campaign/lux-list-travel-awards-uk')} component={LuxAwardPage} />
      <Route exact path={buildPath(match, '/campaign/lux-list-travel-awards-prizes-uk')} component={LuxAwardPage} />
      <Route path={buildPath(match, '/destination')} component={ContentfulWebPage} />
      <Route path={buildPath(match, '/campaign')} component={ContentfulWebPage} />
      <RedirectWithStatus code={301} from={buildPath(match, '/hotels/country/maldives')} to={buildPath(match, '/destination/maldives')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/hotels/country/bali')} to={buildPath(match, '/destination/bali')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/hotels/country/greece')} to={buildPath(match, '/destination/greece')} />
      <RedirectWithStatus code={301} from={buildPath(match, '/hotels/country/spain')} to={buildPath(match, '/destination/spain')} />

      {/* New URL Structure For SEO Destination/Product pages */}
      <Route path={buildPath(match, '/:productType/country/:country')} component={DestinationProductPage} />
      <Route path={buildPath(match, '/:productType/city/:city/:state/:country')} component={DestinationProductPage} />
      <Route path={buildPath(match, '/:productType/city/:city/:country')} component={DestinationProductPage} />
      <Route path={buildPath(match, '/:productType/location/:location/:city/:state/:country')} component={DestinationProductPage} />
      <Route path={buildPath(match, '/:productType/location/:location/:city/:country')} component={DestinationProductPage} />
      <Route path={buildPath(match, '/:productType/location/:location/:country')} component={DestinationProductPage} />

      {/* HolidayPackage Pages: Rule of URL: [base]/holiday-packages/:location/:holidayType */}
      <Route path={buildPath(match, '/holiday-packages/:location/:holidayType')} component={DestinationLandingPage} />
      <Route path={buildPath(match, '/holiday-packages/:location')} component={DestinationLandingPage} />
      <Route path={buildPath(match, '/resorts/:location')} component={DestinationLandingPage} />

      {/* SEO Destination/Product pages */}
      <Route path={buildPath(match, '/:location.:holidayType.:productType')} component={DestinationProductPage} />
      <Route path={buildPath(match, '/:locationOrHolidayType.:productType')} component={DestinationProductPage} />

      {config.LERE_ENABLE_TOP_PICKS_FOR_ME && <Route path={buildPath(match, '/top-picks-for-me')} component={TopPicksForMePage} />}

      <AuthRoute
        showPopup
        path={buildPath(match, '/ancillary-payment/:orderId/:type(deposit-balance|instalment-balance|instalment|reserve-balance|custom-offer|payment-schedule-balance)')}
        component={AncillaryPaymentPage}
      />
      <AuthRoute
        showPopup
        path={buildPath(match, '/ancillary-change-reservation/:orderId/:type(change-dates|change-package|tour-optional-experiences)/:itemId?')}
        component={AncillaryChangeReservationPage}
      />

      <AuthRoute showPopup path={buildPath(match, '/checkout-restore-cart')} component={CheckoutRestoreCartPage} />
      <AuthRoute showPopup path={buildPath(match, '/checkout-gateway')} component={CheckoutGatewayPage} />
      <Route exact path={buildPath(match, '/checkout/:cartId/:step?')}>
        <CheckoutPageRoutes match={match} />
      </Route>
      <Route path={buildPath(match, '/checkout/:step?')}>
        <CheckoutPageRoutes match={match} />
      </Route>
      <BaseRoute path={buildPath(match, '/trip-planner')}>
        <TripPlannerRoutes match={match} />
      </BaseRoute>
      <AuthRoute showPopup path={buildPath(match, '/cart')} component={UserCartPage} />
      <RedirectWithStatus code={301} from={buildPath(match, '/user-cart')} to={buildPath(match, '/cart')} />

      <Route path={buildPath(match, '/splashpage')} component={SplashPage}/>
      <Route exact path={buildPath(match, '/hotels/:holidayType')} component={HolidayTypeLandingPage} />
      <Route exact path={buildPath(match, '/hotels/:holidayType/:country')} component={HolidayTypeLandingPage} />
      <Route exact path={buildPath(match, '/hotels/:holidayType/:country/:cityOrState')} component={HolidayTypeLandingPage} />

      {config.SECURE_PAYMENT_ENABLED &&
        <Route exact path={buildPath(match, '/pay/:paymentLinkId')} component={SecurePaymentPage} />
      }

      {config.agentHub.isEnabled && hasSetupAgentHubAccount &&
        <Switch>
          <Route exact path={buildPath(match, '/agent-hub/account-setup-completed')} component={AgentHubAccountSetupCompleted} />
          <RedirectWithStatus
            from={buildPath(match, '/agent-hub/complete-account-setup')}
            to={buildPath(match, '/agent-hub/account-setup-completed')}
          />
        </Switch>
      }

      {config.SPOOFING_SEND_CONFIRMATION_EMAIL && <Route path={buildPath(match, '/skycheck')} component={SkyCheckConfirmationPage} />}

      {config.businessTraveller.isEnabled && <BusinessTravellerPageRoutes match={match} />}

      {/* Mobile app marketing url, redirect this to home page until we have one */}
      <RedirectWithStatus code={302} from={buildPath(match, '/app')} to={buildPath(match, '/')} />

      <Route>
        <HttpStatus code={404}>
          <Error404PageWithOffers />
        </HttpStatus>
      </Route>
    </Switch>
  )
}

function mapStateToProps(state: App.State) {
  return {
    currentRegionCode: state.geo.currentRegionCode,
    isLuxPlusEnabled: isLuxPlusEnabled(state),
    isLuxLoyaltyEnabled: getIsLuxLoyaltyEnabled(state),
    standaloneFlightsEnabled: isStandaloneFlightsEnabled(state) || isFlightsCredit(state),
    standaloneCruiseEnabled: isStandaloneCruiseEnabled(state),
    isStandaloneAirportTransfersEnabled: getStandaloneAirportTransfersEnabled(state),
  }
}

export default connect(mapStateToProps)(PageRoutes)

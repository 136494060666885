import React, { ComponentProps } from 'react'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { connect } from 'react-redux'
import PriceRowLuxLoyaltyPoints from 'components/Luxkit/PricePoints/LuxLoyalty/PriceRowLuxLoyaltyPoints'
import getLuxLoyaltyDisplayTier from 'luxLoyalty/selectors/getLuxLoyaltyDisplayTier'
import { useInView } from 'react-intersection-observer'
import useLuxLoyaltyPointsEarnCalculator from 'luxLoyalty/hooks/useLuxLoyaltyPointsEarnCalculator'
import PriceRowLuxLoyaltyPointsLoadingSkeleton from 'components/Luxkit/PricePoints/LuxLoyalty/PriceRowLuxLoyaltyPointsLoadingSkeleton'
import { nonNullable } from 'lib/array/arrayUtils'

interface MappedProps {
  luxLoyaltyEnabled: boolean;
  displayTier: App.LuxLoyaltyTier;
}

type InheritedPriceRowLuxLoyaltyPointsProps = Pick<ComponentProps<typeof PriceRowLuxLoyaltyPoints>, 'onInfoIconClick' | 'calculationType'>

interface Props extends MappedProps, InheritedPriceRowLuxLoyaltyPointsProps {
  calculationRequests: Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined> | App.LuxLoyaltyPointsEarnCalculationRequest | undefined;
  /**
   * @default 'S'
   */
  size?: React.ComponentProps<typeof PriceRowLuxLoyaltyPoints>['size'];
  /**
   * @default false
   */
  hideStatusCredits?: boolean;
}

function LuxLoyaltyPoints(props: Props) {
  const {
    luxLoyaltyEnabled,
    calculationRequests,
    size = 'S',
    hideStatusCredits = false,
    calculationType,
    displayTier,
    onInfoIconClick,
  } = props

  const [luxLoyaltyPointsRef, luxLoyaltyPointsInView] = useInView({
    triggerOnce: true,
  })

  const { totalPointsCalculation } = useLuxLoyaltyPointsEarnCalculator(calculationRequests, {
    skipAll: !luxLoyaltyPointsInView,
  })

  const initial = totalPointsCalculation?.initial
  const fetching = !!totalPointsCalculation?.fetching
  const err = totalPointsCalculation?.error
  const points = totalPointsCalculation?.data?.points ?? 0
  const statusCredits = totalPointsCalculation?.data?.statusCredits ?? 0

  if (
    !luxLoyaltyEnabled ||
    !calculationRequests ||
    (Array.isArray(calculationRequests) && nonNullable(calculationRequests).length === 0) ||
    err ||
    (!initial && !fetching && !points)) return null

  return <div ref={luxLoyaltyPointsRef}>
    {fetching && <PriceRowLuxLoyaltyPointsLoadingSkeleton tier={displayTier} size={size} />}
    {!fetching && <PriceRowLuxLoyaltyPoints
      tier={displayTier}
      earnablePoints={points}
      size={size}
      calculationType={calculationType}
      statusCredits={hideStatusCredits ? undefined : statusCredits}
      onInfoIconClick={onInfoIconClick}
    />}
  </div>
}

function mapStateToProps(state: App.State): MappedProps {
  return ({
    luxLoyaltyEnabled: getIsLuxLoyaltyEnabled(state),
    displayTier: getLuxLoyaltyDisplayTier(state),
  })
}

export default connect(mapStateToProps)(LuxLoyaltyPoints)
